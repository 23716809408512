import Div from "../ui/Div";
import PageHeading from "../ui/PageHeading";
import SectionHeading from "../ui/SectionHeading";
import Spacing from "../ui/Spacing";
import ContactInfoWidget from "../ui/Widget/ContactInfoWidget";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import PageHeading2 from "../ui/PageHEading2";
import "./PrivacyPolicyPage.css"; // Make sure to create this CSS file for custom styles

export default function PrivacyPolicyPage() {
  return (
    <>
      <Header />
      <PageHeading2
        title="Privacy Policy"
        bgSrc="/images/privacy_policy_bg.jpeg"
        pageLinkText=""
      />
      <Spacing lg="150" md="80" />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionHeading title="Privacy Policy" subtitle="VulcanTech" />
            <Spacing lg="55" md="30" />
            <div className="cs-text_block">
              <h4>1. Information We Collect</h4>
              <h5>1.1. Information You Provide</h5>
              <ul>
                <li>
                  Account Information: When you create an account, we collect
                  your username, email address, and password.
                </li>
                <li>
                  Profile Information: You may provide additional information
                  such as a profile picture, bio, and social media links.
                </li>
                <li>
                  Customer Support: If you contact us for support, we collect
                  information about your request and how to help you.
                </li>
                <li>
                  Other Information: You may provide information in other ways,
                  such as participating in surveys, contests, or forums.
                </li>
              </ul>
              <h5>1.2. Information We Collect Automatically</h5>
              <ul>
                <li>
                  Device Information: We collect information about the device
                  you use to access our Services, including the hardware model,
                  operating system, and IP address.
                </li>
                <li>
                  Usage Information: We collect information about how you use
                  our Services, such as game progress, achievements, and
                  interactions with other players.
                </li>
                <li>
                  Cookies and Tracking Technologies: We use cookies and similar
                  technologies to collect information about your use of our
                  Services and improve your experience.
                </li>
              </ul>
              <h5>1.3. Information from Third Parties</h5>
              <ul>
                <li>
                  Social Media: If you link your account to social media
                  accounts, we may collect information from those services, such
                  as your friends or contacts lists.
                </li>
                <li>
                  Partners and Service Providers: We may receive information
                  from our partners and service providers to enhance our
                  Services.
                </li>
              </ul>

              <h4>2. How We Use Your Information</h4>
              <ul>
                <li>
                  To Provide and Improve Services: We use your information to
                  operate, maintain, and improve our Services.
                </li>
                <li>
                  To Communicate with You: We use your information to send you
                  updates, newsletters, and respond to your inquiries.
                </li>
                <li>
                  To Personalize Your Experience: We use your information to
                  customize your gaming experience, such as suggesting friends
                  or content you may like.
                </li>
                <li>
                  To Ensure Safety and Security: We use your information to
                  protect our Services and users from fraud, abuse, and other
                  harmful activities.
                </li>
                <li>
                  To Comply with Legal Obligations: We use your information as
                  required by law, regulation, or legal process.
                </li>
              </ul>

              <h4>3. How We Share Your Information</h4>
              <ul>
                <li>
                  With Your Consent: We may share your information with your
                  consent or at your direction.
                </li>
                <li>
                  With Service Providers: We share information with vendors and
                  service providers who help us provide our Services, such as
                  hosting providers, payment processors, and customer support.
                </li>
                <li>
                  With Other Users: Your profile information and in-game
                  activities may be visible to other users of our Services.
                </li>
                <li>
                  For Legal Reasons: We may share your information if we believe
                  it is necessary to comply with legal obligations, protect our
                  rights and property, or prevent fraud or illegal activity.
                </li>
                <li>
                  Business Transfers: If we are involved in a merger,
                  acquisition, or sale of assets, your information may be
                  transferred as part of that transaction.
                </li>
              </ul>

              <h4>4. Your Choices and Rights</h4>
              <ul>
                <li>
                  Access and Update: You can access and update your account
                  information through your account settings.
                </li>
                <li>
                  Delete Your Account: You can delete your account through your
                  account settings or by contacting us. Note that some
                  information may remain in our records after your account is
                  deleted.
                </li>
                <li>
                  Opt-Out of Communications: You can opt-out of receiving
                  promotional communications from us by following the
                  instructions in those communications.
                </li>
                <li>
                  Cookies and Tracking: You can control cookies and tracking
                  technologies through your browser settings.
                </li>
              </ul>

              <h4>5. Security of Your Information</h4>
              <p>
                We take reasonable measures to protect your information from
                unauthorized access, use, or disclosure. However, no security
                measures are perfect, and we cannot guarantee the security of
                your information.
              </p>

              <h4>6. Children's Privacy</h4>
              <p>
                Our Services are not intended for children under the age of 13.
                We do not knowingly collect personal information from children
                under 13. If we learn that we have collected information from a
                child under 13, we will delete it promptly.
              </p>

              <h4>7. Changes to This Privacy Policy</h4>
              <p>
                We may update this Privacy Policy from time to time. If we make
                changes, we will notify you by revising the date at the top of
                the policy and, in some cases, we may provide additional notice
                (such as adding a statement to our homepage or sending you a
                notification).
              </p>

              <h4>8. Contact Us</h4>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                VulcanTech
                <br />
                Email: support@vulcantech.co
                <br />
                Address: 124 City Road, London, EC1V 2NX
              </p>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <Footer />
    </>
  );
}
