import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FreelancerAgencyHome from "../src/freelancer-agency";
import AboutPage from "./about/about";
import ServicesPage from "./service/page";
import PortfolioPage from "./portfolio/page";
import ContactPage from "./contact/page";
import PrivacyPolicyPage from "./privacy_policy/privacy_policy";
import "../src/scss/index.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FreelancerAgencyHome />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/service" element={<ServicesPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        {/* Define other routes here */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
