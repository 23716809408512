import React from "react";
import Div from "../Div";
import Spacing from "../Spacing";

export default function IntroWithImage() {
  const introText =
    "At our company, we are dedicated to pushing the boundaries of innovation and excellence in our field. Our core mission is to deliver solutions that not only meet but exceed our clients' expectations. With years of expertise and a committed team, we ensure that every project is a step towards transformative success. Discover the future of industry-leading technology and services with us. Join our journey to redefine what's possible.";
  const introImage =
    "/images/about/mobile-app-developer-designing-coding-generative-ai.jpg"; // Path to your introduction image

  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-intro_with_image">
            <Div className="cs-intro_text_right">
              <p className="cs-m99">{introText}</p>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
