import Div from "../ui/Div";
import PageHeading from "../ui/PageHeading";
import SectionHeading from "../ui/SectionHeading";
import Spacing from "../ui/Spacing";
import ContactInfoWidget from "../ui/Widget/ContactInfoWidget";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../ui/Header";
import Footer from "../ui/Footer";

export default function ContactPage() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    projectType: "",
    mobile: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_slx762g",
        "template_i7hovym",
        e.target,
        "BwholRo9Pit1uCCgh"
      )
      .then(
        (result) => {
          toast.success("Message sent successfully!");
          setFormData({
            fullName: "",
            email: "",
            projectType: "",
            mobile: "",
            details: "",
          }); // Clear form fields after sending the email
        },
        (error) => {
          toast.error("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <>
      <Header />
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <div className="container">
        <ToastContainer />
        <div className="row">
          <div className="col-lg-6">
            {/* Assuming SectionHeading and Spacing are your custom components */}
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </div>
          <div className="col-lg-6">
            <form className="row" onSubmit={sendEmail}>
              <div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  type="text"
                  name="fullName"
                  className="cs-form_field"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  name="email"
                  className="cs-form_field"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input
                  type="text"
                  name="projectType"
                  className="cs-form_field"
                  value={formData.projectType}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  type="text"
                  name="mobile"
                  className="cs-form_field"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-12">
                <label className="cs-primary_color">Project Details*</label>
                <textarea
                  cols="30"
                  rows="7"
                  name="details"
                  className="cs-form_field"
                  value={formData.details}
                  onChange={handleChange}
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </div>
              <div className="col-sm-12">
                <button className="cs-btn cs-style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      {/* <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </Div> */}
      {/* <Spacing lg="50" md="40" /> */}
      <Footer />
    </>
  );
}
