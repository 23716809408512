import { useState } from "react";
import Cta from "../ui/Cta";
import Div from "../ui/Div";
import Hero8 from "../ui/Hero/Hero8";
import LogoList from "../ui/LogoList";
import MovingText from "../ui/MovingText";
import Portfolio from "../ui/Portfolio";
import SectionHeading from "../ui/SectionHeading";
import ServiceListStyle2 from "../ui/ServiceList/ServiceListStyle2";
import IntroWithImage from "../ui/Slider/TestimonialSlider";
import Spacing from "../ui/Spacing";
import VideoModal from "../ui/VideoModal";
import Header from "../ui/Header";
import { Icon } from "@iconify/react";
import Footer from "../ui/Footer";
const heroSocialLinks = [
  {
    name: "Behance",
    links: "/",
  },
  {
    name: "Twitter",
    links: "/",
  },
];
const portfolioData = [
  {
    title: "LLM Finetuned Chatbot",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/chatbot.png",
    category: "Machine_Learning_AI",
  },
  {
    title: "StockVista",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/StockVista.png",
    category: "Machine_Learning_AI",
  },

  {
    title: "Medical Chatbot",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/medical_chatbot.png",
    category: "Machine_Learning_AI",
  },
  {
    title: "LLM Based Chatbot App",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/LLM.png",
    category: "mobile_apps",
  },
  {
    title: "Facial Recognition Based Attendance System",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/attendance_system.png",
    category: "mobile_apps",
  },
  {
    title: "Social Media Mobile App",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/mobile_app_design.png",
    category: "mobile_apps",
  },
  {
    title: "Web Design 1",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/qamar_saleem.png",
    category: "web_design",
  },
  {
    title: "Web Design 2",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/web_1.png",
    category: "web_design",
  },
  {
    title: "Web Design 3",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/web_2.png",
    category: "web_design",
  },
];
const categoryMenu = [
  {
    title: "Machine Learning & AI",
    category: "Machine_Learning_AI",
  },
  {
    title: "Web Design",
    category: "web_design",
  },
  {
    title: "Mobile Apps",
    category: "mobile_apps",
  },
];

export default function FreelancerAgencyHome() {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(6);
  return (
    <>
      <Header />
      <Hero8
        title="Welcome to Vulcan Tech"
        subtitle="Vulcan Tech stands at the forefront of the software development landscape, with a robust portfolio that includes cutting-edge solutions in Mobile Apps, SaaS platforms, MVPs, Artificial Intelligence/Machine Learning, Cloud Integration, Data Analytics, Cybersecurity, and Microsoft technologies. "
        btnLink="/contact"
        btnText="Let’s talk"
        // socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg_5.jpeg"
        // bannerHighlightText="VT"
        spiningCircleUrl="/images/hero_img.svg"
      />
      <section className="cs-shape_wrap_4 cs-parallax">
        <div className="cs-shape_4 cs-to_up" />
        <div className="cs-shape_4 cs-to_right" />
        <Spacing lg="145" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="We provide best value offer"
                subtitle="Services"
                btnLink="/service"
                btnText="See All Services"
              />
              <Spacing lg="45" md="45" />
            </div>
            <div className="col-lg-7 offset-xl-1">
              <ServiceListStyle2 />
            </div>
          </div>
        </div>
      </section>
      <Spacing lg="115" md="55" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="cs_portfolio_grid_2">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                // href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 4)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start Testimonial Section */}
      <IntroWithImage />
      {/* End Testimonial Section */}

      {/* Start Video Block Section */}
      <Spacing lg="140" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity, not a threat.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal bgUrl="/images/banner/banner_video.png" />
      </Div>
      {/* End Video Block Section */}

      {/* Start MovingText Section */}
      <Spacing lg="55" md="70" />
      {/* <MovingText text="Our reputed world wide partners" /> */}
      {/* <Spacing lg="105" md="70" /> */}
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      {/* <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss to make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      <Footer />
    </>
  );
}
