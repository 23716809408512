import React from "react";
import Split from "../ui/Split/index";
import SectionHeading from "../ui/SectionHeading";
import Spacing from "../ui/Spacing";

const AboutIntro = () => {
  return (
    <section className="intro-section section-padding pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit sm-mb30">
              <SectionHeading
                title="Who we are"
                subtitle="Vulcan Tech"
              ></SectionHeading>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text">
              <Split>
                <p
                  className="wow txt words chars splitting cs-m0"
                  data-splitting
                >
                  Vulcan Tech is a forward-thinking technology firm dedicated to
                  elevating businesses of all sizes through cutting-edge
                  software development. Our mission is to catalyze significant
                  growth for both emerging and established companies by
                  delivering custom software solutions that enhance operational
                  efficiency and drive innovation.<br></br>
                  <Spacing lg="10" md="80" />
                  As seasoned technology specialists, we thrive on transforming
                  ideas into reality. Our strategic consultation approach,
                  combined with agile methodologies, allows us to address
                  complex business dilemmas with precise and scalable technology
                  solutions. <br></br>
                  <Spacing lg="10" md="80" />
                  No matter if you are an entrepreneurial start-up or a
                  multinational corporation, Vulcan Tech is committed to
                  providing bespoke programming and coding services that align
                  perfectly with your project's requirements. This enables you
                  to concentrate on your strategic goals while we expertly
                  manage the technological execution.
                </p>
              </Split>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
