import Cta from "../ui/Cta";
import Div from "../ui/Div";
import FunFact from "../ui/FunFact";
import PageHeading from "../ui/PageHeading";
import SectionHeading from "../ui/SectionHeading";
import TeamSlider from "../ui/Slider/TeamSlider";
import Spacing from "../ui/Spacing";
import aboutImg from "../assets/images/about_img_1.jpeg";
import aboutImg1 from "../assets/images/about/saas-concept-collage.jpg";
import aboutImg2 from "../assets/images/about/businessmen-closing-deal-with-handshake.jpg";
import aboutImg3 from "../assets/images/about/mobile-app-developer-designing-coding-generative-ai.jpg";
import aboutImg4 from "../assets/images/about/confident-businesswoman-smiling-computer-office-generated-by-ai.jpg";
import Header from "../ui/Header";
import AboutIntro from "./aboutintro";
import Footer from "../ui/Footer";

const funfaceData = [
  {
    title: "Global Happy Clients",
    // factNumber: "40K",
  },
  {
    title: "Project Completed",
    // factNumber: "50K",
  },
  {
    title: "Team Members",
    // factNumber: "245",
  },
  {
    title: "Digital products",
    // factNumber: "550",
  },
];

export default function AboutPage() {
  return (
    <>
      <Header />
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="/images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}
      <br></br>
      <AboutIntro />
      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your trusted partner for business"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                This is the main factor that sets us apart from our competition
                and allows us to deliver a specialist business consultancy
                service. Our team applies its wide-ranging experience to
                determining. Through our years of experience, we’ve also learned
                that while each channel.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img src={aboutImg1} alt="About" className="w-100 cs-radius_15" />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img src={aboutImg2} alt="About" className="w-100 cs-radius_15 " />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img src={aboutImg3} alt="About" className="w-100 cs-radius_15" />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Vulcan Tech at a Glance"
          subtitle="Discover how Vulcan Tech is pioneering advancements in technology with state-of-the-art solutions that empower businesses to excel in the digital age. Join us as we lead the charge towards a smarter, more connected world."
          // data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src={aboutImg4}
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Highly experienced people with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Our distinctive approach sets us apart from the competition,
                enabling us to offer specialized business consultancy services.
                We leverage extensive industry experience to tailor our
                strategies specifically to meet the diverse needs of each
                client. Our commitment to excellence and innovation allows us to
                deliver bespoke solutions that not only address current business
                challenges but also pave the way for future growth.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Our competitive advantage lies in our ability to understand and
                adapt to the intricacies of business across various industries.
                Each strategy is crafted with precision, tailored to the unique
                requirements of our clients. This tailored approach ensures that
                our solutions are innovative, effective, and capable of
                transforming conventional business strategies into exceptional
                outcomes.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss to make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      <Footer />
    </>
  );
}
