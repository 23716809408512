import React, { useState } from "react";
import Div from "../Div";
export default function VideoModal({ bgUrl, variant }) {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  const handleClose = () => {
    setToggle(false);
  };

  return (
    <>
      <Div
        className={`cs-video_block ${
          variant ? variant : "cs-style1"
        } cs-video_open cs-bg`}
        style={{ backgroundImage: `url(${bgUrl})` }}
        onClick={handleClick}
      >
        <span className="cs-player_btn cs-accent_color">
          <span />
        </span>
      </Div>
      <Div className={toggle ? "cs-video_popup active" : "cs-video_popup"}>
        <Div className="cs-video_popup_overlay" onClick={handleClose} />
        <Div className="cs-video_popup_content">
          <Div className="cs-video_popup_layer" />
          <Div className="cs-video_popup_container">
            <Div className="cs-video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <video className="embed-responsive-item" controls>
                  <source src="/video/video-4.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div aria-hidden="true" style={{ display: "none" }}>
                  Music I use:{" "}
                  <a
                    href="https://www.bensound.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bensound.com
                  </a>
                  <br />
                  License code: 3KLVTDRM6KAWVYLY
                </div>
              </div>
            </Div>
            <Div className="cs-video_popup_close" onClick={handleClose} />
          </Div>
        </Div>
      </Div>
    </>
  );
}
