import Cta from "../ui/Cta";
import Div from "../ui/Div";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import PageHeading from "../ui/PageHeading";
import Portfolio from "../ui/Portfolio";
import SectionHeading from "../ui/SectionHeading";
import Spacing from "../ui/Spacing";
import { Icon } from "@iconify/react";
import { useState } from "react";

const portfolioData = [
  {
    title: "LLM Finetuned Chatbot",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/chatbot.png",
    category: "Machine_Learning_AI",
  },
  {
    title: "StockVista",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/StockVista.png",
    category: "Machine_Learning_AI",
  },

  {
    title: "Medical Chatbot",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/medical_chatbot.png",
    category: "Machine_Learning_AI",
  },
  {
    title: "LLM Based Chatbot App",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/LLM.png",
    category: "mobile_apps",
  },
  {
    title: "Facial Recognition Based Attendance System",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/attendance_system.png",
    category: "mobile_apps",
  },
  {
    title: "Social Media Mobile App",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/mobile_app_design.png",
    category: "mobile_apps",
  },
  {
    title: "Web Design 1",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/qamar_saleem.png",
    category: "web_design",
  },
  {
    title: "Web Design 2",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/web_1.png",
    category: "web_design",
  },
  {
    title: "Web Design 3",
    subtitle: "",
    href: "/portfolio/portfolio-details",
    src: "/images/portfolio/web_2.png",
    category: "web_design",
  },
];
const categoryMenu = [
  {
    title: "Machine Learning & AI",
    category: "Machine_Learning_AI",
  },
  {
    title: "Web Design",
    category: "web_design",
  },
  {
    title: "Mobile Apps",
    category: "mobile_apps",
  },
];

export default function PortfolioPage() {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(7);

  return (
    <>
      <Header />
      <PageHeading
        title="Portfolio"
        bgSrc="/images/portfolio_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@vulcantech.co"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
      <Footer />
    </>
  );
}
